import { ref } from 'vue';
import { defineStore } from 'pinia';
import {FeedbackForm} from "@/src/types/feedbacks";

export const useFeedbacksStore = defineStore('notification', () => {
  const feedbackForm = ref<FeedbackForm>({
    chatId: null, //chat_id
    message: '', //feedback
    rating: null // feedback_rate
  });
  const savingFeedbackLoader = ref(false);
  const chatMode = ref('');
  const advisorInfo = ref();

  const resetFeedbackForm = ()  => {
    feedbackForm.value = {
      chatId: null,
      message: '',
      rating: null
    };
    chatMode.value = ''
    advisorInfo.value = null
  }

  const setSavingFeedbackLoader = (value)  => {
    savingFeedbackLoader.value = value
  }

  const setFeedbackFormChatId = (id)  => {
    feedbackForm.value.chatId = id
  }

  const setFeedbackForm = (form: FeedbackForm)  => {
    feedbackForm.value = form
  }

  const setChatMode = (mode)  => {
    chatMode.value = mode
  }

  const setAdvisorInfo = (info)  => {
    advisorInfo.value = info
  }

  return {
    feedbackForm,
    advisorInfo,
    chatMode,
    resetFeedbackForm,
    setSavingFeedbackLoader,
    setFeedbackFormChatId,
    setFeedbackForm,
    setAdvisorInfo,
    setChatMode
  };
});
